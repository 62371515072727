@import url("https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: "Inter", sans-serif !important;
}

body {
  font-family: "Inter", sans-serif !important;
  margin: 0px;
  overflow: hidden;
}
/* 
.MuiDataGrid-panelWrapper {
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-shadow: 4px 20px 24px rgba(0, 0, 0, 0.08),
    inset -1px -2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
}

.MuiDataGrid-panel {
  top: -70px !important;
  left: 10px !important;
} */

#newMsgText:focus {
  outline: none;
}

.ct__content {
  visibility: hidden;
}
.ct__content > div {
  visibility: visible;
  display: block;
  width: 5rem;
  z-index: 2;
  position: absolute;
  top: 0px;
  left: -34px;
  padding: 4px;
  border-radius: 12px;
  background-color: black;
}

.ct__content:after {
  content: "Reorder text";
  visibility: visible;
  display: block;
  position: absolute;
  top: 5px;
  /* background-color: red; */
}


